import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CustomerCard from '../../components/CustomerCard';
import Layout from '../../components/Layout';
import OverlayBox from '../../components/OverlayBox';
import Pagination from '../../components/Pagination';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { allCounties } from '../../utils/data';
import { colors } from '../../utils/siteVars';
import { request } from '../../utils/request';
import DataListSearchBox from '../../components/DataListSearchBox';

const StyledCustomersPage = styled.div`
  .munip-reg-container {
    min-width: 80vw;
    .munip-reg-header {
      font-weight: 500;
      color: ${colors.mediumGrey};
    }
    .munip-reg-row {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 1rem 0;
      p.right {
        text-align: right;
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: ${colors.mediumGrey};
        content: '';
      }
    }
  }
  .sorting-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    .filter-header-container {
      display: flex;
      align-items: center;
      p {
        white-space: nowrap;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  @media (max-width: 1000px) {
    .sorting-container {
      .filter-header-container {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 0;
        p {
          margin-bottom: 0.3rem;
        }
        select {
          width: 100%;
        }
      }
    }
  }
`;

const CustomersPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [countySort, setCountySort] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sortOrder, setSortOrder] = useState('name');
  const [munipRegOpen, setMunipRegOpen] = useState(false);
  const [munipRegData, setMunipRegData] = useState([]);
  const [currentCustomersPage, setCurrentCustomersPage] = useState(0);
  const [numberOfCustomers, setNumberOfCustomers] = useState();
  const [customerNamesAndIds, setCustomerNamesAndIds] = useState([]);

  const getMunipReg = async () => {
    const { data } = await request({
      state,
      dispatch,
      path: 'munipreg',
    });
    if (!data) return;
    const sorted = data.sort((a, b) =>
      a.munipName.localeCompare(b.munipName, 'sv')
    );
    setMunipRegData(sorted);
  };

  const LIMIT = 30;

  const getCustomerNames = async () => {
    const { data } = await request({
      state,
      dispatch,
      path: 'customers/datalist-names',
      skipLoader: true,
    });
    if (!data) return;
    setCustomerNamesAndIds(data.customers);
  };

  const getCustomers = async (q) => {
    const query = q || '';
    const { data } = await request({
      state,
      dispatch,
      path: `customers/?page=${currentCustomersPage}&sortOrder=${sortOrder}&county=${countySort}&q=${query}&limit=${LIMIT}&limitPayload=true`,
      errorText: 'Kunde inte ladda kunder',
    });
    if (!data) return;
    setNumberOfCustomers(data.numberOfCustomers);
    setFilteredCustomers(data.customers);
  };

  const commitSearch = (e) => {
    e.preventDefault();
    if (searchString.length >= 3) {
      getCustomers(searchString);
    }
  };

  const handleSearchStringChange = (e) => {
    setSearchString(e.target.value);
    const exactMatch = customerNamesAndIds.find(
      (customer) => customer.name === e.target.value
    );
    if (exactMatch) {
      navigate(`/customers/single/?id=${exactMatch._id}`);
    }
  };

  const getData = async () => {
    const promises = [getCustomers(), getCustomerNames()];
    await Promise.all(promises);
  };

  useEffect(() => {
    if (state.token) getData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token, currentCustomersPage, sortOrder, countySort]);

  return (
    <Layout>
      <StyledCustomersPage data-test="customers-page">
        <h1 className="page-header">Kunder</h1>
        <Breadcrumbs
          crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Kunder' }]}
          rightContent={
            <div className="customer-crumbs-right-content">
              <Button
                small
                white
                onClick={() => {
                  setMunipRegOpen(true);
                  getMunipReg();
                }}
              >
                Kommunregister
              </Button>
              {state.user.role === 'Admin' ? (
                <Link to="/admin">
                  <Button small data-test="handle-customers-button">
                    Hantera kunder
                  </Button>
                </Link>
              ) : null}
            </div>
          }
        />
        <Card className="sorting-container">
          <div className="filter-header-container">
            <p className="light">Filtrera</p>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              value={countySort}
              onChange={(e) => {
                setCountySort(e.target.value);
              }}
            >
              <option value="">Alla län</option>
              {allCounties.map((county) => (
                <option value={county} key={county}>
                  {county}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-header-container">
            <p className="light"> Sortera</p>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="name">Namn</option>
              <option value="lastContact">Sista kontakt</option>
              <option value="serviceDeal">Serviceavtal slutdatum</option>
            </select>
          </div>
          <DataListSearchBox
            onSubmit={commitSearch}
            placeholder={'Sök namn'}
            onChange={handleSearchStringChange}
            value={searchString}
            items={customerNamesAndIds}
            itemNameKey={'name'}
            itemKey={'_id'}
            identifier={'search-customers'}
          />
        </Card>
        <Pagination
          limit={LIMIT}
          numberOfCustomers={numberOfCustomers}
          page={currentCustomersPage}
          setPage={setCurrentCustomersPage}
        />

        <div className="customers">
          {filteredCustomers.length > 0 ? (
            filteredCustomers.map((customer) => (
              <CustomerCard key={customer._id} customer={customer} />
            ))
          ) : (
            <p>Det finns inga kunder att visa</p>
          )}
        </div>
        <Pagination
          limit={LIMIT}
          numberOfCustomers={numberOfCustomers}
          page={currentCustomersPage}
          setPage={setCurrentCustomersPage}
        />
        <OverlayBox
          header="Kommunregister"
          close={() => setMunipRegOpen(false)}
          active={munipRegOpen}
        >
          <div className="munip-reg-container">
            <div className="munip-reg-row">
              <p className="munip-reg-header">Kommun</p>
              <p className="munip-reg-header">Kundnamn</p>
            </div>
            {munipRegData.map((reg) => (
              <div className="munip-reg-row" key={reg._id}>
                <p>{reg.munipName}</p>
                <p className="right">{reg.customerName}</p>
              </div>
            ))}
          </div>
        </OverlayBox>
      </StyledCustomersPage>
    </Layout>
  );
};

export default CustomersPage;
